import gql from 'graphql-tag'

export const LSDNetworksQuery = gql`
  query getLSDNetworks($liquidStakingManager: Bytes!) {
    liquidStakingNetworks(where: { liquidStakingManager: $liquidStakingManager }) {
      id
      feesAndMevPool
      savETHPool
      feeRecipientAndSyndicate
    }
  }
`
